<template>
  <section v-if="homepage.heading || homepage.importantNotice" class="relative">
    <UtilityCrossBackground
      v-if="homepage.importantNotice && isNoticeStillValid"
      first-cross-class="hidden lg:block absolute right-0 top-0 rotate-12"
      second-cross-class="hidden lg:block absolute left-0 -bottom-36 -rotate-12"
      :color="parishConfig.colors.crossDefault"
    />
    <div class="container__content">
      <AnimatedObserver>
        <Heading v-if="homepage.heading" class="text-center" :size="1" :text="homepage.heading" />
      </AnimatedObserver>
      <AnimatedObserver>
        <Notice
          v-if="homepage.importantNotice && isNoticeStillValid"
          class="mt-20 lg:mt-40"
          :heading="homepage.importantNotice.heading"
          :text="homepage.importantNotice.text"
          :validity-from="homepage.importantNotice.validityFrom"
        />
      </AnimatedObserver>
    </div>
  </section>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon'

const homepage = useHomepage()
const parishConfig = useParishConfig()
const isNoticeStillValid = computed(() => {
  const { validityTo } = homepage.value.importantNotice
  if (!validityTo) return true

  const today = DateTime.now().startOf('day')
  const validityDate = DateTime.fromISO(validityTo).startOf('day')
  return today <= validityDate
})
</script>
